
import useGetQueryPagination from "./helper/ueGetPagination";
import useAddMutation from "./helper/useAddMutation"
import useDeleteMutation from "./helper/useDeleteMutation"
import useGetOneQuery from "./helper/useGetOneQuery";
import useGetQuery from "./helper/useGetQuery"
import useUpdateMutation from "./helper/useUpdateMutation";
  
const API = {
  ADD: `service/link/add`,
  GET_ALL: `service/link/getAll`,
  DELETE: `service/link/delete`,
  UPDATE: `service/link/update`,

};
const KEY = "service/link"


export const useGetlink = (params?:any) => useGetQueryPagination(KEY, API.GET_ALL,params);
export const useAddlink = () => useAddMutation(KEY, API.ADD);
export const useUpdatelink = () => useUpdateMutation(KEY, API.UPDATE);

export const useDeletelink = () =>useDeleteMutation(KEY, API.DELETE);
