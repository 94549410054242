export const Languages = () => [
  { "label": "en (English)", "value": "us", "full_name": "English" },
  { "label": "ar (Arabic)", "value": "sa", "full_name": "Arabic" },
  { "label": "es (Spanish)", "value": "es", "full_name": "Spanish" },
  { "label": "fr (French)", "value": "fr", "full_name": "French" },
  { "label": "de (German)", "value": "de", "full_name": "German" },
  { "label": "ru (Russian)", "value": "ru", "full_name": "Russian" },
  { "label": "pt (Portuguese)", "value": "pt", "full_name": "Portuguese" },
  { "label": "it (Italian)", "value": "it", "full_name": "Italian" },
  { "label": "tr (Turkish)", "value": "tr", "full_name": "Turkish" },
  { "label": "vi (Vietnamese)", "value": "vi", "full_name": "Vietnamese" },
  { "label": "pl (Polish)", "value": "pl", "full_name": "Polish" },
  { "label": "nl (Dutch)", "value": "nl", "full_name": "Dutch" },
  { "label": "th (Thai)", "value": "th", "full_name": "Thai" },
  { "label": "sv (Swedish)", "value": "sv", "full_name": "Swedish" },
  { "label": "fi (Finnish)", "value": "fi", "full_name": "Finnish" },
  { "label": "zh (Chinese)", "value": "cn", "full_name": "Chinese" },
  { "label": "ja (Japanese)", "value": "jp", "full_name": "Japanese" },
  { "label": "ko (Korean)", "value": "kr", "full_name": "Korean" },
  { "label": "hi (Hindi)", "value": "in", "full_name": "Hindi" },
  { "label": "bn (Bengali)", "value": "bd", "full_name": "Bengali" },
  { "label": "fa (Persian)", "value": "ir", "full_name": "Persian" },
  { "label": "ur (Urdu)", "value": "pk", "full_name": "Urdu" },
  { "label": "he (Hebrew)", "value": "il", "full_name": "Hebrew" },
  { "label": "el (Greek)", "value": "gr", "full_name": "Greek" },
  { "label": "hu (Hungarian)", "value": "hu", "full_name": "Hungarian" },
  { "label": "cs (Czech)", "value": "cz", "full_name": "Czech" },
  { "label": "da (Danish)", "value": "dk", "full_name": "Danish" },
  { "label": "no (Norwegian)", "value": "no", "full_name": "Norwegian" },
  { "label": "id (Indonesian)", "value": "id", "full_name": "Indonesian" },
  { "label": "ms (Malay)", "value": "my", "full_name": "Malay" },
  { "label": "uk (Ukrainian)", "value": "ua", "full_name": "Ukrainian" },
  { "label": "ro (Romanian)", "value": "ro", "full_name": "Romanian" },
  { "label": "sk (Slovak)", "value": "sk", "full_name": "Slovak" },
  { "label": "bg (Bulgarian)", "value": "bg", "full_name": "Bulgarian" },
  { "label": "hr (Croatian)", "value": "hr", "full_name": "Croatian" },
  { "label": "sr (Serbian)", "value": "rs", "full_name": "Serbian" },
  { "label": "lt (Lithuanian)", "value": "lt", "full_name": "Lithuanian" },
  { "label": "lv (Latvian)", "value": "lv", "full_name": "Latvian" },
  { "label": "et (Estonian)", "value": "ee", "full_name": "Estonian" },
  { "label": "sl (Slovenian)", "value": "si", "full_name": "Slovenian" }
]
