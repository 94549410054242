


const PROJECT_NAME = "Hijab_DAHBOARD"

export const TOKEN_KEY = PROJECT_NAME +  "_TOKEN"
export const TOKEN_KEY_SOCKET = PROJECT_NAME +  "_SOKCET_TOKEN"

export const USER_KEY =  PROJECT_NAME + "_USER"


/// 1825|laravel_sanctum_RpRqXDf1jg1Jgb0VjnJcUzPpSmX46PD7h8jB8ag372d0778a
export const languages: string[] = ['en', 'ar', 'de', 'tr', 'ru'];

// export const languages: string[] = ['en'];