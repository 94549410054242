import { languages } from "../../config/AppKey"


export const changeShapeValue = (values:any , attribute : string) =>{

        const langs  =languages


        langs.forEach( (lan:string)=> {
                if(lan === 'en'){
                    return 
                }

                if(!values[attribute + '_'+ lan]  || values[attribute + '_'+ lan] === ' '){
                    values[attribute + '_'+ lan] = values[attribute + "_en"]
                }
        });
        return values
}