
import React from 'react'
import { Col, Row } from 'reactstrap';
import ValidationField from '../../../Components/ValidationField/ValidationField';
import { useFormikContext } from 'formik';

import { useTranslation } from 'react-i18next';
import { langauge_field_genrater } from '../../../Hooks/useLanguageGenrater';
import { useGetService } from '../../../api/Service';
import useFormatToSelect from '../../../Hooks/useFormatToSelect';
import {Nationalities} from '../../../Data/nationality'
import { Languages } from '../../../Data/languages';
function Form() {
  const formik = useFormikContext<any>();
  const [t] = useTranslation();
  const {data} = useGetService()    
  const SelectData = useFormatToSelect(data,"title")
  const nats  = Nationalities() 
  const langs  = Languages() 

  return (
    <Row xs={1} sm={1} md={2} lg={3} xl={3}>
    <Col>
    {langauge_field_genrater(["name"])}
    <ValidationField name="image" type="File" />



    </Col>
    <Col>
    {langauge_field_genrater(["type"])}

    <ValidationField name="languages" type='Select' option={langs}   isMulti />

    </Col>
    <Col>
    {langauge_field_genrater(["description"])}

    <ValidationField name="nationality" type='Select' option={nats} />

    </Col>
    <ValidationField name="service_ids" type='Select' option={SelectData} isMulti  />


   
  </Row>
  )
}

export default Form


