
import React from 'react'
import { Col, Row } from 'reactstrap';
import ValidationField from '../../../Components/ValidationField/ValidationField';
import { useFormikContext } from 'formik';

import { useTranslation } from 'react-i18next';
import { langauge_field_genrater } from '../../../Hooks/useLanguageGenrater';

function Form() {
  const formik = useFormikContext<any>();
  const [t] = useTranslation();
  console.log("IBRAHIMMM");
  

  return (
    <Row xs={1} sm={1} md={2} lg={2} xl={2}>
    <Col>
    {langauge_field_genrater(["name"])}


  
    </Col>
    <Col>   
    {langauge_field_genrater(["price"])}
    {langauge_field_genrater(["whatsapp_view"])}


  
    </Col>



   
  </Row>
  )
}

export default Form


