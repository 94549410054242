
export const ImageBaseURL = `https://ahlapi.kammun.com`;
// export const ImageBaseURL = 'http://localhost:8000'

export const BaseURL_IMAGE = ImageBaseURL;

export const BaseURL = `${ImageBaseURL}/api/admin/`;


const PROJECT_NAME = "Hijab"

export const TOKEN_KEY = PROJECT_NAME +  "_TOKEN"

export const USER_KEY =  PROJECT_NAME + "_USER"


